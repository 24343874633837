import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useAdmin } from "./AdminContext";

const categories = [
  {
    genre: "tv-show",
    caption: "Scripted Series",
    menuItems: [
      "drama",
      "action",
      "classic",
      "horror",
      "romance",
      "thriller",
      "crime",
      "comedy",
      "mystery",
      "sci-fi",
      "family",
      "sports",
    ],
  },
  {
    genre: "movie",
    caption: "Movie",
    menuItems: [
      "action",
      "classic",
      "horror",
      "romance",
      "thriller",
      "crime",
      "comedy",
      "mystery",
      "sci-fi",
      "family",
    ],
  },
  {
    genre: "factual",
    caption: "Factual",
    menuItems: [
      "wild-life",
      "nature",
      "religious",
      "health",
      "technology",
      "crime-investigation",
      "sports",
      "political",
      "history",
      "supernatural",
      "lifestyle",
      "travel",
      "food",
      "fashion",
    ],
  },
  {
    genre: "entertainment",
    caption: "Entertainment",
    menuItems: [
      "comedy-sketch",
      "game-show",
      "singing-competition",
      "cooking-competition",
      "dance-competition",
      "reality-show",
      "talk-show",
      "musicals",
      "concerts",
    ],
  },
  {
    genre: "kids",
    caption: "Kids",
    menuItems: [
      "animation",
      "live-action",
      "musical",
      "entertainment",
      "religious",
      "reality-show",
    ],
  },
];

function Menu() {
  const { isAdmin, logoutAdmin } = useAdmin();
  return (
    <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
      <Navbar.Brand href="/" className="logo__box">
        <img src="/astro.png" alt="Astro logo" className="logo" />
        <p className="logo__text">Content Distribution</p>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto menu">
          <Nav.Link href="/" className="menu__item">
            Home
          </Nav.Link>
          {categories.map((category) => (
            <NavDropdown
              className="menu__item"
              title={category.caption}
              id="basic-nav-dropdown"
              key={category.caption}
            >
              <NavDropdown.Item href={`/content/genre/${category.genre}`}>
                {`All ${category.caption}`}
              </NavDropdown.Item>
              {category.menuItems.map((item) => (
                <NavDropdown.Item
                  href={`/content/genre/${category.genre}?subGenre=${item}`}
                  key={`${category.genre}-${item}`}
                >
                  {item}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          ))}
          <Nav.Link href="/contact-us" className="menu__item">
            Contact Us
          </Nav.Link>
          {isAdmin && (
            <NavDropdown
              className="menu__item"
              title="Admin"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/admin/import-content">
                Import Contents
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => logoutAdmin()}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          )}
          <Nav.Link href="/content/search" className="menu__item">
            <span className="menu__search">
              {/* <p className="menu__search-text">Search</p> */}
              <svg className="menu__search-icon">
                <use xlinkHref="/sprite.svg#icon-search"></use>
              </svg>
            </span>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Menu;
