import { Button, Form, Col, Row } from "react-bootstrap";

import Footer from "./Footer";
import Menu from "./Menu";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Loader";
import adminAuth from "./AdminAuth";

function AdminUpdateContent() {
  const authToken = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : "";
  const param = useParams();
  const id = param.id;
  const [contentDetails, setContentDetails] = useState({
    audioLanguageLabel: "",
    certification: "",
    duration: "",
    episodeNumber: "",
    genre: "",
    isHighlight: false,
    isSoldOut: false,
    longSynopsis: "",
    posters: {},
    seasonNumber: "",
    subGenre: [],
    subTitle: [],
    tags: [],
    yearOfRealease: 0,
    trailerUrl: "",
  });
  const [newUpdate, setNewUpdate] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  function identifyChanges() {
    const changes = {};
    Object.keys(contentDetails).forEach((key) => {
      if (contentDetails[key] !== newUpdate[key]) {
        changes[key] =
          key === "subGenre" || key === "subTitle"
            ? convertToArray(newUpdate[key])
            : newUpdate[key];
      }
    });
    return changes;
  }

  function convertToArray(stringValues) {
    return stringValues
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item.length > 1);
  }

  function handleChange(e) {
    const { name, value, type, checked } = e.target;
    setNewUpdate({
      ...newUpdate,
      [name]: type === "checkbox" ? checked : value,
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const updates = identifyChanges();

    try {
      const data = {
        contentId: id,
        updates,
      };
      const rawResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/admin/update-content`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken.token,
          },
          body: JSON.stringify(data),
        }
      );
      const results = await rawResponse.json();
      if (results.length > 0) {
        navigate(`/content/${id}`);
      }
    } catch (error) {
      console.log("🚀 There is an error submitting request", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(
    function () {
      async function fetchContentDetails() {
        setIsLoading(true);
        try {
          const rawResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_API}/content/${id}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          const content = await rawResponse.json();
          if (content) {
            const {
              actors,
              dataPlatform,
              directors,
              mediaFormat,
              posters,
              shortSynopsis,
              ...restContent
            } = content;
            setContentDetails({
              ...contentDetails,
              ...restContent,
            });
            setNewUpdate(restContent);
          }
        } catch (error) {
          console.log(error);
          throw new Error();
        } finally {
          setIsLoading(false);
        }
      }
      fetchContentDetails();
    },
    [id]
  );

  return (
    <div className="App">
      <Menu />
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="update">
          <h1>Update Content</h1>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridId">
                <Form.Label>Id</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Id"
                  value={newUpdate.id}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridPackageId">
                <Form.Label>Package Id</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="PackageId"
                  value={newUpdate.packageId}
                  disabled
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Title"
                  value={newUpdate.title}
                  disabled
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridLanguage">
                <Form.Label>Language</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Language"
                  value={newUpdate?.audioLanguageLabel}
                  name="audioLanguageLabel"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formEpisodeNumber">
                <Form.Label>Episode Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter episode Number"
                  value={newUpdate.episodeNumber}
                  name="episodeNumber"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridDuration">
                <Form.Label>Duration (minutes)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Duration"
                  value={newUpdate.duration}
                  name="duration"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridGenre">
                <Form.Label>Genre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Genre"
                  value={newUpdate.genre}
                  name="genre"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridSubGenre">
                <Form.Label>Sub Genre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Sub Genre"
                  value={
                    typeof newUpdate.subGenre === "object"
                      ? newUpdate.subGenre.join(", ")
                      : newUpdate.subGenre
                  }
                  name="subGenre"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formGridSynopsis">
              <Form.Label>Synopsis</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="synopsis"
                value={newUpdate.longSynopsis}
                name="longSynopsis"
                onChange={handleChange}
              />
            </Form.Group>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridClasisification">
                <Form.Label>Clasification</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Clasification"
                  value={newUpdate.certification}
                  name="certification"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridRelease">
                <Form.Label>Release Year</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Release"
                  value={newUpdate.yearOfRealease}
                  name="yearOfRealease"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridSubtitles">
                <Form.Label>Subtitles</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Subtitles"
                  value={
                    typeof newUpdate.subTitle === "object"
                      ? newUpdate.subTitle.join(", ")
                      : newUpdate.subTitle || ""
                  }
                  name="subTitle"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="formGridTrailerUrl">
                <Form.Label>Trailer URL</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Trailer URL"
                  value={newUpdate.trailerUrl || ""}
                  name="trailerUrl"
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Highlight"
                  name="isHighlight"
                  checked={newUpdate.isHighlight}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Sold Out"
                  name="isSoldOut"
                  checked={newUpdate.isSoldOut}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default adminAuth(AdminUpdateContent);
