import { useState, useEffect } from "react";
// import Loader from "./Loader";
import Menu from "./Menu";
import Footer from "./Footer";
import ContentCard from "./ContentCard";

function SearchContent() {
  const authToken = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : "";
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState(query);
  const [contents, setContents] = useState([]);

  useEffect(
    function () {
      const handler = setTimeout(() => {
        if (query.length === 0) {
          setContents([]);
        }
        if (query.length > 2) {
          setSearchQuery(query);
        }
      }, 1000);

      return () => {
        clearTimeout(handler);
      };
    },
    [query]
  );

  useEffect(() => {
    async function fetchContents(searchQuery) {
      setIsLoading(true);
      try {
        const rawResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/content/all?searchQ=${searchQuery}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: authToken?.token || null,
            },
          }
        );
        const contents = await rawResponse.json();
        if (contents.length > 0) {
          setContents(contents);
        }
      } catch (error) {
        console.log(error);
        throw new Error();
      } finally {
        setIsLoading(false);
      }
    }
    if (searchQuery) {
      fetchContents(searchQuery);
    }
  }, [searchQuery]);

  return (
    <div className="App">
      <Menu />
      <section className="home-listing pad-5">
        <div className="filter__query">
          <input
            className="filter__query-searchbox"
            type="text"
            placeholder="Search by title"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <button className="filter__query-btn">
            <svg className="filter__query-icon">
              <use xlinkHref="/sprite.svg#icon-search"></use>
            </svg>
          </button>
        </div>
        <div className="content-container">
          {isLoading && <SearchLoading />}
          {!isLoading &&
            contents.map((content) => (
              <ContentCard
                key={content.id}
                content={content}
                synopsic={false}
              />
            ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}

function SearchLoading() {
  return <p className="search-loading">Loading...</p>;
}

export default SearchContent;
