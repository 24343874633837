// import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import * as _ from "lodash";

import Loader from "./Loader";
import AutoFilter from "./AutoFilter";
import ContentCard from "./ContentCard";
import NoContentFound from "./NoContent";
import { useParams, useSearchParams } from "react-router-dom";
import Menu from "./Menu";
import Footer from "./Footer";

function ContentList() {
  const param = useParams();
  const genre = param.genreName;
  const [searchParams] = useSearchParams();
  const subGenre = searchParams.get("subGenre") || "";

  const [contents, setContents] = useState([]);
  const [FilteredContents, setFilteredContents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({
    query: "",
    genre: subGenre,
    audio: "",
    yearOfRealease: "",
    sort: {
      key: "title",
      direction: "asc",
    },
  });

  function filterContentBySubGenre(array, subGenre) {
    return array.filter((content) =>
      content.subGenre.some((sub) =>
        sub.toLowerCase().includes(subGenre.toLowerCase())
      )
    );
  }

  useEffect(() => {
    let updatedContents = [...contents];

    if (filters.query && filters.query.length > 2) {
      updatedContents = updatedContents.filter((content) =>
        content.title.toLowerCase().includes(filters.query.toLowerCase())
      );
    }

    if (filters.audio) {
      updatedContents = updatedContents.filter(
        (content) => content.audioLanguageLabel === filters.audio
      );
    }

    if (filters.genre) {
      updatedContents = updatedContents.filter((content) =>
        content.subGenre.some((sub) =>
          sub.toLowerCase().includes(filters.genre.toLowerCase())
        )
      );
    }

    if (filters.yearOfRealease) {
      console.log(
        "🚀 ~ useEffect ~ filters.yearOfRealease:",
        filters.yearOfRealease
      );
      console.log("🚀 ~ useEffect ~ updatedContents:", updatedContents);
      updatedContents = updatedContents.filter(
        (content) => +content.yearOfRealease === +filters.yearOfRealease
      );
      console.log("🚀 ~ useEffect ~ updatedContents-after:", updatedContents);
    }

    if (filters.sort.direction && filters.sort.key) {
      updatedContents = _.orderBy(
        updatedContents,
        [filters.sort.key],
        [filters.sort.direction]
      );
    }

    setFilteredContents(updatedContents);
  }, [filters, contents]);

  useEffect(
    function () {
      async function fetchContents() {
        setIsLoading(true);
        try {
          const rawResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_API}/content/genre/${genre}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          const contents = await rawResponse.json();
          // console.log("🚀 ~ fetchContents ~ contents:", contents);
          if (contents.length > 0) {
            const contentByGenre = subGenre
              ? filterContentBySubGenre(contents, subGenre)
              : contents;
            setContents(contentByGenre);
            setFilteredContents(contentByGenre);
          }
        } catch (error) {
          console.log(error);
          throw new Error();
        } finally {
          setIsLoading(false);
        }
      }

      fetchContents();
    },
    [genre, subGenre]
  );

  function updateFilters(key, value) {
    if (key === "reset") {
      setFilters({
        query: "",
        genre: "",
        audio: "",
        yearOfRealease: "",
        sort: {
          key: "title",
          direction: "asc",
        },
      });
      return;
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  }

  return (
    <div className="App">
      {/* <NavBar /> */}
      <Menu />
      <section className="content-listing" id="content-listing">
        {!isOpen && (
          <div className="filter-icon">
            <svg
              className="rating__box-share-icon"
              onClick={() => setIsOpen(!isOpen)}
            >
              <use xlinkHref="/sprite.svg#icon-filter"></use>
            </svg>
          </div>
        )}
        <div className="listing-container">
          <AutoFilter
            contents={contents}
            filters={filters}
            updateFilters={updateFilters}
            isOpen={isOpen}
            close={() => setIsOpen(!isOpen)}
          />
          <div className="content-container">
            {isLoading && <Loader />}
            {!isLoading &&
              FilteredContents.map((content) => (
                <ContentCard
                  key={content.id}
                  content={content}
                  synopsic={false}
                />
              ))}
            {FilteredContents.length < 1 && !isLoading && <NoContentFound />}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ContentList;
