import { Link } from "react-router-dom";

import Carousel from "react-bootstrap/Carousel";
const images = [
  {
    title: "Hantu Mok Jok",
    image:
      "https://s3-ap-southeast-1.amazonaws.com/ams-astro/production/images/JZGXZ.jpg",
    detail: "/content/79ddc74e-9d84-458c-9d40-084ccff14126",
  },
  {
    title: "Gracias Barcelona",
    image:
      "https://s3-ap-southeast-1.amazonaws.com/ams-astro/production/images/JWHXY.jpg",
    detail: "/content/f93ac661-a23e-4673-8eba-6297dffd135e",
  },
  {
    title: "Jangan Takut, Kau Takut Aku pun Takut",
    image:
      "https://s3-ap-southeast-1.amazonaws.com/ams-astro/production/images/JUDVS.jpg",
    detail: "/content/5a0c4dfe-ec91-40ca-a412-072a6d1fc2eb",
  },
  {
    title: "Halimun",
    image:
      "https://s3-ap-southeast-1.amazonaws.com/ams-astro/production/images/KAYBA.jpg",
    detail: "/content/6ff33d30-25d1-46d2-940f-1f8e55706b3f",
  },
];

function ContentSlider() {
  return (
    <Carousel className="slider-container">
      {images.map((slide) => (
        <Carousel.Item key={slide.title}>
          <img className="poster-image" src={slide.image} alt={slide.title} />
          <Link to={slide.detail}>
            <button className="poster-button">View Details</button>
          </Link>
          <Carousel.Caption>
            <h3>{slide.title}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ContentSlider;
