function NoContentFound() {
  return (
    <div className="no-content">
      <svg className="no-content__icon">
        <use xlinkHref="/sprite.svg#icon-wondering"></use>
      </svg>
      <p className="no-content__text">No Content Fount...</p>
    </div>
  );
}

export default NoContentFound;
