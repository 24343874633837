function Footer() {
  return (
    <div className="footer">
      <div className="footer__left">
        <div className="footer__logo-box ">
          <img src="/astro.png" alt="Astro logo" className="footer__logo" />
          <p className="footer__logo-text">Content Distribution</p>
        </div>
        {/* <p className="footer__address">
          Copyright © 2024 <br /> All Asia Broadcast Center, Technology Park
          Malaysia, Bukit Jalil, 57000 Kuala Lumpur
        </p>
        <div className="footer__contact">
          <p className="footer__contact-text">
            For more information please contanct <br />
            Astro Content Sales : <strong>content_sales@astro.com.my</strong>
          </p>
        </div> */}
      </div>
      <div className="footer__right">
        {/* <div className="footer__newsletter">
          <p className="footer__newsletter-title">
            Drop us your email to our monthly newsletter
          </p>
          <form action="" className="footer__newsletter-form">
            <input type="text" placeholder="user@email.com" />
            <button type="submit">Submit</button>
          </form>
        </div> */}
        <p className="footer__address">
          Copyright © 2024 <br /> All Asia Broadcast Center, Technology Park
          Malaysia, Bukit Jalil, 57000 Kuala Lumpur
        </p>
        <div className="footer__contact">
          <p className="footer__contact-text">
            For more information please contact <br />
            Astro Content Sales : <strong>content_sales@astro.com.my</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
