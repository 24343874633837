import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Footer from "./Footer";
import Menu from "./Menu";
import { useState } from "react";
import Loader from "./Loader";
import { useAdmin } from "./AdminContext";
import { useNavigate } from "react-router-dom";

function AdminLogin() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { loginAsAdmin } = useAdmin();
  const navigate = useNavigate();

  async function handleAdminLogin(event) {
    setIsLoading(true);
    event.preventDefault();

    try {
      const data = {
        userName,
        password,
      };
      // console.log("🚀 ~ handleAdminLogin ~ data:", data);
      const rawResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/admin/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const { token, exp } = await rawResponse.json();
      if (token) {
        loginAsAdmin(token, exp);
        navigate("/");
      }
      //login
    } catch (error) {
      console.log("🚀 There is an error submitting request", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="App">
      <Menu />
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="login">
          <h1>Login</h1>
          <Form onSubmit={handleAdminLogin}>
            <Form.Group className="mb-3" controlId="formBasicUserName">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="userName"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter username"
                required
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default AdminLogin;
