// import { useState } from "react";

function AutoFilter({ contents, filters, updateFilters, isOpen, close }) {
  function getUniqueValue(array, elementName) {
    const unique = new Set(array.map((item) => item[elementName]));
    return [...unique];
  }

  function getUniqueSubGenre(array) {
    const allSubGenre = array.flatMap((obj) => obj.subGenre);
    const unique = [...new Set(allSubGenre)];
    return unique;
  }

  return (
    isOpen && (
      <div className="filter">
        <div className="filter__header">
          <h3>Filters</h3>
          <svg className="filter__close-icon" onClick={close}>
            <use xlinkHref="/sprite.svg#icon-cancel-circle"></use>
          </svg>
        </div>
        <div className="filter__form">
          <div className="filter__query">
            <input
              className="filter__query-searchbox"
              type="text"
              placeholder="Search by title"
              value={filters.query}
              onChange={(e) => updateFilters("query", e.target.value)}
            />
            <button className="filter__query-btn">
              <svg className="filter__query-icon">
                <use xlinkHref="/sprite.svg#icon-search"></use>
              </svg>
            </button>
          </div>

          <div className="filter__sort">
            <span className="filter__sort-label">Title</span>
            <button
              type="button"
              className="filter__sort-btn"
              onClick={() =>
                updateFilters("sort", {
                  key: "title",
                  direction: "asc",
                })
              }
            >
              {/* <svg className="filter__sort-icon filter__sort-icon_active"> */}
              <svg
                className={`filter__sort-icon ${
                  filters.sort.key === "title" &&
                  filters.sort.direction === "asc"
                    ? "filter__sort-icon_active"
                    : ""
                } `}
              >
                <use xlinkHref="/sprite.svg#icon-arrow-up2"></use>
              </svg>
            </button>
            <button
              type="button"
              className="filter__sort-btn"
              onClick={() =>
                updateFilters("sort", {
                  key: "title",
                  direction: "desc",
                })
              }
            >
              <svg
                className={`filter__sort-icon ${
                  filters.sort.key === "title" &&
                  filters.sort.direction === "desc"
                    ? "filter__sort-icon_active"
                    : ""
                } `}
              >
                <use xlinkHref="/sprite.svg#icon-arrow-down2"></use>
              </svg>
            </button>
          </div>
          <div className="filter__sort">
            <span className="filter__sort-label">Year Of Release</span>
            <button
              type="button"
              className="filter__sort-btn"
              onClick={() =>
                updateFilters("sort", {
                  key: "yearOfRealease",
                  direction: "asc",
                })
              }
            >
              <svg
                className={`filter__sort-icon ${
                  filters.sort.key === "yearOfRealease" &&
                  filters.sort.direction === "asc"
                    ? "filter__sort-icon_active"
                    : ""
                } `}
              >
                <use xlinkHref="/sprite.svg#icon-arrow-up2"></use>
              </svg>
            </button>
            <button
              type="button"
              className="filter__sort-btn"
              onClick={() =>
                updateFilters("sort", {
                  key: "yearOfRealease",
                  direction: "desc",
                })
              }
            >
              <svg
                className={`filter__sort-icon ${
                  filters.sort.key === "yearOfRealease" &&
                  filters.sort.direction === "desc"
                    ? "filter__sort-icon_active"
                    : ""
                } `}
              >
                <use xlinkHref="/sprite.svg#icon-arrow-down2"></use>
              </svg>
            </button>
          </div>
          <FilterButtons
            title="audio"
            buttons={getUniqueValue(contents, "audioLanguageLabel")}
            filters={filters}
            updateFilters={updateFilters}
          />

          <FilterButtons
            title="genre"
            buttons={getUniqueSubGenre(contents)}
            filters={filters}
            updateFilters={updateFilters}
          />

          <FilterButtons
            title="yearOfRealease"
            buttons={getUniqueValue(contents, "yearOfRealease")}
            filters={filters}
            updateFilters={updateFilters}
          />
          <div className="filter__reset">
            <button
              type="button"
              className="filter__reset-btn"
              onClick={() => updateFilters("reset")}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    )
  );
}

function FilterButtons({ title, buttons, filters, updateFilters }) {
  return (
    <fieldset className="filter-buttons">
      <legend>{title === "yearOfRealease" ? "Year Of Release" : title}</legend>
      {buttons.map((button) => (
        <input
          key={button}
          type="radio"
          name={button}
          label={button}
          value={button}
          checked={filters[title] == button}
          onChange={(e) => updateFilters(title, e.target.value)}
        />
      ))}
    </fieldset>
  );
}

export default AutoFilter;
