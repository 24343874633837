import { useNavigate } from "react-router-dom";
import { useAdmin } from "./AdminContext";
import { useEffect } from "react";

const adminAuth = (WrappedComponent) => {
  return (props) => {
    const { isAdmin, logoutAdmin } = useAdmin();
    const navigate = useNavigate();

    useEffect(() => {
      if (!isAdmin) {
        logoutAdmin();
        navigate("/admin/login");
      }
    }, [isAdmin, logoutAdmin, navigate]);

    return isAdmin ? <WrappedComponent {...props} /> : null;
  };
};

export default adminAuth;
