import { Link } from "react-router-dom";

// import data from "../data.js";
import ContentCard from "./ContentCard.jsx";

function getHighlightsGenre(data, genre) {
  // return data.filter((content) =>
  //   content.subGenre.some((sub) =>
  //     sub.toLowerCase().includes(genre.toLowerCase())
  //   )
  // );
  return data.filter((ct) =>
    ct.genre.toLowerCase().includes(genre.toLowerCase())
  );
}

function HomeHighlights({ genres, highlights }) {
  // const topRated = data.filter((content) =>
  //   content.subGenre.some((sub) =>
  //     sub.toLowerCase().includes(title.toLowerCase())
  //   )
  // );

  return (
    // <div className="highlight__container">
    //   <h2 className="highlight__title">{title}</h2>
    //   <div className="highlight__content">
    //     {topRated.map((content) => (
    //       <ContentCard key={content.id} content={content} synopsic={false} />
    //     ))}
    //     <Link to="/content" style={{ textDecoration: "none" }}>
    //       <div className="highlight__viewmore">
    //         <svg className="highlight__viewmore-icon">
    //           <use xlinkHref="/sprite.svg#icon-tv"></use>
    //         </svg>
    //         <span className="highlight__viewmore-span">View more</span>
    //       </div>
    //     </Link>
    //   </div>
    // </div>
    genres.map((genre) => (
      <Highlight
        title={genre}
        highlights={getHighlightsGenre(highlights, genre)}
        key={genre}
      />
    ))
  );
}

export default HomeHighlights;

function Highlight({ title, highlights }) {
  return (
    <div className="highlight__container">
      <h2 className="highlight__title">{title}</h2>
      <div className="highlight__content">
        {highlights.map((content) => (
          <ContentCard key={content.id} content={content} synopsic={false} />
        ))}
        <Link to={`content/genre/${title}`} style={{ textDecoration: "none" }}>
          <div className="highlight__viewmore">
            <svg className="highlight__viewmore-icon">
              <use xlinkHref="/sprite.svg#icon-tv"></use>
            </svg>
            <span className="highlight__viewmore-span">View more</span>
          </div>
        </Link>
      </div>
    </div>
  );
}
