function Hero() {
  return (
    <div className="hero">
      <video
        src="https://d38l0fm9l6dngy.cloudfront.net/hero.mp4"
        autoPlay
        muted
        loop
        className="hero__video-bg"
      />
      <div className="hero__video-bg-overlay"></div>
      <div className="hero__video-bg-title">Acquire Premium Movie Rights</div>
      <div className="hero__video-bg-sub">
        Discover and secure exclusive content
      </div>
    </div>
  );
}

export default Hero;
