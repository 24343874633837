function Loader() {
  // return <p className="loader">Loading...</p>;
  return (
    <div className="loader__overlay">
      {/* <img src="/loading.gif" alt="Loading..." className="loader__gif" /> */}
      <img src="/film-loading.gif" alt="Loading..." className="loader__gif" />
    </div>
  );
}

export default Loader;
