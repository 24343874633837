import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

function AdminImportContentCard({ content, handleImport }) {
  return (
    <Card style={{ width: "18rem" }}>
      <Card.Body>
        <Card.Title className="text-cap">{content.title}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {`${content.genre} - ${content.audioLanguageLabel}`}
        </Card.Subtitle>
        <Card.Text>{content.shortSynopsis}</Card.Text>
        <Button
          variant="primary"
          onClick={() => {
            if (window.confirm(`Are you sure to import ${content.title}`)) {
              handleImport(content);
            }
          }}
        >
          Import
        </Button>
      </Card.Body>
    </Card>
  );
}

export default AdminImportContentCard;
