import Menu from "./Menu";
import ContentSlider from "./ContentSlider";
import HomeHighlights from "./HomeHighlight";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import Loader from "./Loader";

// const genres = ["Comedy", "Horror", "Romance", "Drama"];
const genres = ["movie", "tv-show", "factual"];

function Home() {
  const [highlights, setHighlights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(function () {
    async function fetchHighlights() {
      setIsLoading(true);
      try {
        const rawResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_API}/content/highlights`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const res = await rawResponse.json();
        // console.log("🚀 ~ fetchHighlights ~ contents:", contents);
        if (res.length > 0) {
          setHighlights(res);
        }
      } catch (error) {
        console.log(error);
        throw new Error();
      } finally {
        setIsLoading(false);
      }
    }

    fetchHighlights();
  }, []);

  return (
    <div className="App">
      <Menu />
      <ContentSlider />
      <section className="home-listing" id="content-listing">
        <div className="content-container">
          {isLoading && <Loader />}
          {!isLoading && (
            <>
              <HomeHighlights genres={genres} highlights={highlights} />
            </>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
