import { Link } from "react-router-dom";

// const MAX_CHAR = 110;

function ContentCard({ content, synopsic }) {
  const genres = [content.genre, ...content.subGenre];

  return (
    <div className="card">
      <img
        src={
          content.posters?.listing?.fileUrl
            ? content.posters.listing.fileUrl
            : content.posters.detail.fileUrl
        }
        alt={`movie poster - ${content.title}`}
        className="card__header"
      />
      <div
        className="card__detail"
        style={{ height: synopsic ? "15rem" : "7rem" }}
      >
        <div className="card__detail-genre">
          {genres.map((genre) => (
            <GenreCapsule key={genre} genre={genre} />
          ))}
        </div>
        <h3 className="card__detail-title">{content.title}</h3>
        {synopsic && (
          <p className="card__detail-synopsis">{content.shortSynopsis}</p>
        )}
      </div>
      <Link to={`/content/${content.id}`}>
        <button className="card__button" type="button">
          <span>View Details</span>
        </button>
      </Link>
    </div>
  );
}

function GenreCapsule({ genre }) {
  return (
    <div
      className="genre"
      style={{
        backgroundColor: `${GenreColor[genre] ? GenreColor[genre] : "#800080"}`,
      }}
    >
      {genre}
    </div>
  );
}

const GenreColor = {
  movie: "#2D2D2D",
  "tv-show": "#4B0082",
  horror: "#8B0000",
  supernatural: "#483D8B",
  comedy: "#FF8C00",
  drama: "#800080",
  romance: "#FF69B4",
  thriller: "#000000",
  family: "#FF4500",
  series: "#1E90FF",
  food: "#32CD32",
};

export default ContentCard;
