import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import Footer from "./Footer";
import Menu from "./Menu";
import { useState } from "react";
import Loader from "./Loader";
import AdminImportContentCard from "./AdminImportContentCard";
import ImportToast from "./ImportToast";
import adminAuth from "./AdminAuth";

function AdminImportContent() {
  const [contents, setContents] = useState([]);
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("token") || "")
  );
  const [searchQ, setSearchQ] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setToastShow] = useState(false);
  const [toastContent, setToastContent] = useState({
    type: "",
    title: "",
    message: "",
  });

  async function handleSearch(event) {
    event.preventDefault();
    console.log("🚀 ~ handleSearch ~ token:", token);

    setIsLoading(true);
    const packIdList = searchQ.split(",").map((q) => q.trim());
    try {
      const data = {
        packageId: packIdList,
      };
      const rawResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/admin/import-content`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token.token,
          },
          body: JSON.stringify(data),
        }
      );

      const results = await rawResponse.json();
      if (results.length > 0) {
        setSearchQ("");
        setContents(results);
      }
    } catch (error) {
      console.log("🚀 There is an error submitting request", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleImport(content) {
    setIsLoading(true);
    try {
      // console.log("🚀 ~ handleSearch ~ data:", content);
      const rawResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/admin/save-content`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token.token,
          },
          body: JSON.stringify(content),
        }
      );

      const { success, message } = await rawResponse.json();
      // console.log("🚀 ~ handleImport ~ success, message:", success, message);
      if (success) {
        setToastContent({
          type: "light",
          title: "success",
          message,
        });
        setToastShow(true);
        setContents(contents.filter((c) => c.id !== content.id));
      } else if (message === "content already exists") {
        setToastContent({
          type: "warning",
          title: "duplicate content",
          message,
        });
        setToastShow(true);
        setContents(contents.filter((c) => c.id !== content.id));
      }
    } catch (error) {
      console.log("🚀 There is an error saving content", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="App">
      <Menu />
      <ImportToast
        showToast={showToast}
        setToastShow={setToastShow}
        type={toastContent.type}
        title={toastContent.title}
        message={toastContent.message}
      />
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="import">
          <h1>Import Content</h1>
          <Form onSubmit={handleSearch}>
            <Form.Group className="mb-3" controlId="formPackageId">
              <Form.Label>Package Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter packageId"
                value={searchQ}
                onChange={(e) => setSearchQ(e.target.value)}
              />
              <Form.Text className="text-muted">
                Enter packageId to import
              </Form.Text>
            </Form.Group>
            <Button variant="primary" type="submit">
              Search
            </Button>
          </Form>
          {contents.length > 0 && (
            <>
              <h3 className="import__title">Contents</h3>
              <div className="import__container">
                {contents.map((content) => (
                  <AdminImportContentCard
                    key={content.id}
                    content={content}
                    handleImport={handleImport}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      <Footer />
    </div>
  );
}

export default adminAuth(AdminImportContent);
