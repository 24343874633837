import { useState } from "react";
import Menu from "./Menu";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import adminAuth from "./AdminAuth";

function AdminTrailerUpload() {
  const authToken = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : "";
  const param = useParams();
  const id = param.id;
  const [videoFile, setVideoFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  const uploadFile = async () => {
    if (!videoFile) {
      setErrorMessage("Please select a video file to upload.");
      return;
    }

    setUploading(true);
    setErrorMessage("");

    try {
      // Step 1: Request a presigned URL from your backend
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/admin/add-trailer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken?.token,
          },
          body: JSON.stringify({
            fileName: videoFile.name,
            fileType: videoFile.type,
          }),
        }
      );

      const uploadUrl = await response.json();

      if (!uploadUrl) {
        setErrorMessage(`Unable to get pre-signed URL`);
      }

      const uploadResponse = await fetch(uploadUrl, {
        method: "PUT",
        body: videoFile,
        headers: {
          "Content-Type": videoFile.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      if (!uploadResponse.ok) {
        throw new Error("Failed to upload the file.");
      }

      const updateData = {
        contentId: id,
        updates: {
          trailerUrl: `https://d38l0fm9l6dngy.cloudfront.net/${videoFile.name}`,
        },
      };
      const updateTrailerResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/admin/update-content`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken?.token,
          },
          body: JSON.stringify(updateData),
        }
      );
      const results = await updateTrailerResponse.json();
      if (results.length < 0) {
        throw new Error("Failed to update link.");
      }

      alert("File uploaded successfully!");
      document.getElementById("file-input").value = "";
      setVideoFile(null);
    } catch (error) {
      setErrorMessage(`Upload failed: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="App">
      <Menu />
      <div className="login">
        <h1>Upload Video</h1>
        <input
          id="file-input"
          type="file"
          accept="video/mp4"
          onChange={handleFileChange}
        />
        <button onClick={uploadFile} disabled={uploading}>
          {uploading ? `Uploading ${uploadProgress}%...` : "Upload"}
        </button>
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
      <Footer />
    </div>
  );
}

export default adminAuth(AdminTrailerUpload);
