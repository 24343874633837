import { Button, Form as BootstrapForm, Col, Row } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import Footer from "./Footer";
import Hero from "./Hero";
import Menu from "./Menu";

function ContactUs() {
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters long"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    companyName: Yup.string()
      .required("Company Name is required")
      .min(2, "Enter valid company name"),
    country: Yup.string().required("Country is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string()
      .required("Message is required")
      .min(10, "Message must be at least 10 characters long"),
  });

  const subjects = [
    {
      values: "Content Enquiry",
      label: "Content Enquiry",
    },
    {
      values: "Newsletter Subscription",
      label: "Newsletter Subscription",
    },
    {
      values: "Others",
      label: "Others",
    },
  ];

  return (
    <div className="App">
      <Menu />
      <Hero />
      <div className="contact">
        <h1>Contact Us</h1>
        <Formik
          initialValues={{
            name: "",
            email: "",
            companyName: "",
            country: "",
            subject: "",
            message: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const data = {
                subject: values.subject,
                name: values.name,
                emailAddress: values.email,
                country: values.country,
                companyName: values.companyName,
                message: values.message,
              };
              const res = await fetch(
                `${process.env.REACT_APP_BACKEND_API}/screener-request`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(data),
                }
              );

              const result = await res.json();
              console.log("🚀 ~ onSubmit={ ~ result:", result);
            } catch (error) {
              console.log("🚀 There is an error submitting request", error);
            } finally {
              setSubmitting(false);
            }

            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
            //   setSubmitting(false);
            //   handleClose();
            // }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="request__screener-form">
              <BootstrapForm.Group
                as={Row}
                className="mb-3"
                controlId="formName"
              >
                <BootstrapForm.Label column sm="2">
                  Name
                </BootstrapForm.Label>
                <Col sm="10">
                  <Field name="name" type="text" className="form-control" />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </BootstrapForm.Group>

              <BootstrapForm.Group
                as={Row}
                className="mb-3"
                controlId="formEmail"
              >
                <BootstrapForm.Label column sm="2">
                  Email
                </BootstrapForm.Label>
                <Col sm="10">
                  <Field name="email" type="email" className="form-control" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </BootstrapForm.Group>

              <BootstrapForm.Group
                as={Row}
                className="mb-3"
                controlId="formCompanyName"
              >
                <BootstrapForm.Label column sm="2">
                  Company Name
                </BootstrapForm.Label>
                <Col sm="10">
                  <Field
                    name="companyName"
                    type="text"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="companyName"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </BootstrapForm.Group>

              <BootstrapForm.Group
                as={Row}
                className="mb-3"
                controlId="formCountry"
              >
                <BootstrapForm.Label column sm="2">
                  Country
                </BootstrapForm.Label>
                <Col sm="10">
                  <Field name="country" type="text" className="form-control" />
                  <ErrorMessage
                    name="country"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </BootstrapForm.Group>
              <BootstrapForm.Group
                as={Row}
                className="mb-3"
                controlId="formSubject"
              >
                <BootstrapForm.Label column sm="2">
                  Subject
                </BootstrapForm.Label>
                <Col sm="10">
                  <Field as="select" name="subject" className="form-control">
                    <option value="" label="Select Subject" />
                    {subjects.map((sbj) => (
                      <option
                        value={sbj.values}
                        label={sbj.label}
                        key={sbj.values}
                      />
                    ))}
                    <ErrorMessage
                      name="subject"
                      component="div"
                      className="text-danger"
                    />
                  </Field>
                </Col>
              </BootstrapForm.Group>

              <BootstrapForm.Group
                as={Row}
                className="mb-3"
                controlId="formMessage"
              >
                <BootstrapForm.Label column sm="2">
                  Message
                </BootstrapForm.Label>
                <Col sm="10">
                  <Field
                    name="message"
                    as="textarea"
                    className="form-control"
                    rows="4"
                  />
                  <ErrorMessage
                    name="message"
                    component="div"
                    className="text-danger"
                  />
                </Col>
              </BootstrapForm.Group>

              <Button className="contact__submit" type="submit">
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
