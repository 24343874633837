import { createContext, useContext, useEffect, useState } from "react";

const AdminContext = createContext();
const BUFFER_TIME = 20000; //20 seconds
// const BUFFER_TIME = 6840000;

export const AdminProvider = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(() => {
    const savedIsAdmin = localStorage.getItem("isAdmin");
    // return savedIsAdmin === "true";
    const adminData = JSON.parse(localStorage.getItem("token"));

    if (savedIsAdmin === "true" && adminData) {
      const { exp } = adminData;

      if (+Date.now() < +exp - BUFFER_TIME) {
        return true;
      } else {
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("token");
        return false;
      }
    }
  });

  useEffect(() => {
    let timer;
    const adminData = JSON.parse(localStorage.getItem("token"));

    if (adminData) {
      const { exp } = adminData;
      const remainingTime = exp - Date.now() - BUFFER_TIME;

      if (remainingTime > 0) {
        timer = setTimeout(() => {
          logoutAdmin();
        }, remainingTime);
      } else {
        logoutAdmin();
      }
    }

    return () => clearTimeout(timer);
  }, [isAdmin]);

  const loginAsAdmin = (token, exp) => {
    setIsAdmin(true);
    localStorage.setItem("isAdmin", true);
    localStorage.setItem("token", JSON.stringify({ token, exp }));
  };

  const logoutAdmin = () => {
    setIsAdmin(false);
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("token");
  };

  return (
    <AdminContext.Provider value={{ isAdmin, loginAsAdmin, logoutAdmin }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
