import { useParams } from "react-router-dom";
import ContentDetail from "./ContentDetail";

function App() {
  const param = useParams();
  const packId = param.packId;

  return (
    <div className="App">
      {/* <NavBar /> */}
      <ContentDetail packId={packId} />
    </div>
  );
}

export default App;
